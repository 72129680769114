import dynamic from "next/dynamic";
import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

type IconFactory = (props: Props) => JSX.Element;

const wrapIcon: (Factory: IconFactory) => IconFactory = (
  Factory: IconFactory
) =>
  function wrappedIcon(props: Props) {
    return <Factory data-testid="lazy-hero-icons" {...props} />;
  };

export const HeroIconSolid = (
  x: keyof typeof import("@heroicons/react/solid")
) =>
  dynamic<Props>(
    import("@heroicons/react/solid").then((mod) => wrapIcon(mod[x]))
  );

export const HeroIconOutline = (
  x: keyof typeof import("@heroicons/react/outline")
) =>
  dynamic<Props>(
    import("@heroicons/react/outline").then((mod) => wrapIcon(mod[x]))
  );
