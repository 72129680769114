import { WindowLocationAssign } from "@/util";

import { HeroIconOutline } from "./LazyHeroIcons";

const ArrowLeftIcon = HeroIconOutline("ArrowLeftIcon");

export const Error = () => {
  return (
    <div className="w-screen h-screen ">
      <div className="flex flex-col items-center justify-center h-full space-y-16 ">
        <div className="flex flex-col items-center space-y-4">
          <h1 className="text-6xl font-bold">Oops!</h1>
          <h1 className="px-8 text-sm font-semibold text-center text-gray-800">
            There appears to be an issue on our end - our developers have been
            notified. Please contact support if this issue persists.
          </h1>
        </div>
        <div
          role={"button"}
          className="inline-flex items-center flex-shrink-0 gap-2 px-5 py-1 text-white bg-black rounded cursor-pointer hover:bg-blue-light hover:text-white "
          onClick={() => WindowLocationAssign("/")}
        >
          <ArrowLeftIcon className="w-5 h-5" />
          <h1>Home</h1>
        </div>
      </div>
    </div>
  );
};
