import {
  AppInsightsErrorBoundary,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import React, { PropsWithChildren } from "react";

import { Error } from "@/components/ui/Error";
import { reactPlugin } from "@/config/appInsights";

const AppInsights = ({ children }: PropsWithChildren) => {
  return (
    <AppInsightsErrorBoundary
      onError={() => <Error />}
      appInsights={reactPlugin}
    >
      <>{children}</>
    </AppInsightsErrorBoundary>
  );
};

export default withAITracking(reactPlugin, AppInsights);
